import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Layout } from "../components/layout/layout";
import { SsyRadioButton } from "../components/ssyRadioButton/ssyRadioButton";
import Seo from "../components/seo/seo";
import { SsyIntersectionObserver } from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import { RaisingBox } from "../components/raisingBox/raisingBox";
import { Feature } from "../components/feature/feature";
import style from "../assets/css/joinAsAdmin.module.css";
import GetApp from '../assets/images/getApp.svg';
import WebIcon from '../assets/images/webIcon.svg';
import { Separator } from "../components/separator/separator";
import { SsyLink } from "../components/ssyLink/ssyLink";

const JoinPage = (props) => {
  const radioButtons = {
    'getApp': {
      id: 'getApp'
    },
    'joinFromWeb': {
      id: 'joinFromWeb'
    }
  }
  const [selectedItem, setSelectedItem] = useState();
  const [eventCode, setEventCode] = useState();
  const [isIosUser, setIsIosUser] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    setIsIosUser((/iPad|iPhone|iPod/.test(userAgent) || /Mac/i.test(userAgent)) && !window.MSStream);
  }, [])

  const intl = useIntl();
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    setEventCode(window.sessionStorage.getItem('eventSecret'));
    if (/android/i.test(userAgent)) {
      window.location.assign(`${process.env.GATSBY_ANDROID_URL}`);
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.assign(
        `${process.env.GATSBY_IOS_URL}${intl.formatMessage({
          id: "MAIN_LANGUAGE",
        })}`
      );
    }
  }, []);

  const onSelectedItemChanged = (id) => {
    setSelectedItem(id);
  }

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "JOIN_META_TITLE" })}
        lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: "JOIN_META_DESCRIPTION" })}
      />
      <section className={style.join}>
        <SsyIntersectionObserver>
          <RaisingBox>
            <Feature image={"3-join-event"} />
          </RaisingBox>
        </SsyIntersectionObserver>
        <div className={style.optionsContainer}>
          <SsyRadioButton
            isSelected={selectedItem === radioButtons.getApp.id}
            title={intl.formatMessage({ id: 'GET_APP_TITLE' })}
            description={intl.formatMessage({ id: 'GET_APP_DESCRIPTION' }, { code: eventCode })}
            image={GetApp}
            onClick={() => onSelectedItemChanged(radioButtons.getApp.id)}
          />
          <Separator intl={intl} />
          <SsyRadioButton
            isSelected={selectedItem === radioButtons.joinFromWeb.id}
            title={intl.formatMessage({ id: 'JOIN_FROM_WEB_TEXT' })}
            description={intl.formatMessage({ id: 'JOIN_FROM_WEB_DESCRIPTION' })}
            image={WebIcon}
            onClick={() => onSelectedItemChanged(radioButtons.joinFromWeb.id)}
          />

          <SsyLink
            to={selectedItem === 'joinFromWeb'
              ? `${process.env.GATSBY_DASHBOARD_URL}/${intl.formatMessage(
                {
                  id: "MAIN_LANGUAGE",
                }
              )}/login`
              : (isIosUser ? `${process.env.GATSBY_IOS_URL}${intl.formatMessage({
                id: "MAIN_LANGUAGE",
              })}` :
                `${process.env.GATSBY_ANDROID_URL}`)}
            type="primary"
            id="LANDING_IDEAS_BUTTON"
            internal={false}
            label={intl.formatMessage({
              id: "LANDING_IDEAS_BUTTON",
            })}
          />
        </div>
      </section>
    </Layout >
  );
};

export default JoinPage;
