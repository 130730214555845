import React from 'react';
import style from './ssyRadioButton.module.css';
import RadioButtonCheckBox from '../../assets/images/radioButtonCheckBox.svg';
import RadioButtonCheckMark from '../../assets/images/radioButtonCheckMark.svg';

export const SsyRadioButton = ({
    isSelected,
    title,
    description,
    image,
    onClick
}) => {
    return ( 
        <div className={`${style.ssyRadioButtonContainer} ${isSelected ? `${style.selected}` : `${style.unselected}`}`} onClick={onClick}>
            <img className={style.image} src={image} />
            <div className={style.text}>
                <h2 className={style.title}>{title}</h2>
                <p className={style.description}>{description}</p>
            </div>
            <img className={style.checkMark} src={isSelected ? RadioButtonCheckMark : RadioButtonCheckBox} />
        </div>
    );
};
