import React from 'react';
import style from './separator.module.css';

export const Separator = ({ intl }) => {
    return <div className={style.separator}>
        <span className={style.line}/>
        <div className={style.text}>{intl.formatMessage({ id: 'SEPARATOR_TEXT' })}</div>
        <span className={style.line}/>
    </div>
};
